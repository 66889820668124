<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">Costos Internos</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="GUARDAR"
              icon="pi pi-save"
              class="p-button-success mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>


        <div class="">
          <div class="formgrid grid">
              
            <div class="field col-12">
              <label for="formu">Precio por Hora de Operario</label>
              <InputNumber  mode="decimal" :minFractionDigits="2" v-model="this.form.pph_operator"  class="inputfield w-full" />
              <small> Costo calculado por minuto: {{ toArs(form.pph_operator / 60) }}</small>
            </div>


            <div class="field col-12">
              <label for="formu">Historial</label>
              <DataTable
                :value="form.history"
                rows="10"
              > 
                <Column field="created_at" header="Fecha">
                  <template #body="slotProps">
                    <span>{{ parseDate(slotProps.data.created_at) }}</span>
                  </template>
                </Column>
                <Column field="pph_operator" header="Valor Hora" >

                  <template #body="slotProps">
                  <span>{{ toArs(slotProps.data.pph_operator) }}</span>
                  </template>
                </Column>

                </DataTable>  
              </div>  
              


        </div>  
        </div>  

      </div>
    </div>

  </div>
</template>

<script>
import CrudService from "../services/crud.service.js";
import moment from "moment";

export default {
  data() {
    return {

      form: { 
        pph_operator: 100,
        history: [
        ]

      }, 
    };
  },
  computed: {
    dolarValue() {
      switch (this.form.moneda.value) {
        case 1:
          return this.configuraciones.dolar_billete;
        case 3:
          return this.configuraciones.dolar_divisa; 
        default:
          return this.configuraciones.dolar_billete;
      }

    },
    totalItems() {
      
      let total = 0;

      this.items.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    totalMOD() {
      
      let total = 0;

      this.mod.forEach(item => {
        console.log(item)
        total += item.valor * item.qty;
      });

      return total;

    },
    calculos() {
// Costo de Produccion
// MPI
// MOI
// CIIF
// Costo de General

      let data = 
      [
        {
          name: "Subtotal",
          valor: this.totalItems
        },
       /* {
          name: "IVA",
          valor: this.mpi
        },
        {
          name: "Otros Impuestos",
          valor: this.moi
        },
        {
          name: "Total",
          valor: this.ciif
        },*/
        // {
        //   name: "Costo de General",
        //   valor:  this.totalMPD + this.totalMOD + this.mpi + this.moi + this.ciif
        // },
      ];

 
    
      if(this.form.descuento > 0) {
        // to negative
        data.push({
          name: "Descuento",
          valor: this.totalItems * (-this.form.descuento / 100)
        })
      }

      if(this.form.impuesto.value > 0) {

        let descuento = 0;
        if(this.form.descuento > 0) {
          descuento = this.totalItems * (-this.form.descuento / 100);
        }


        data.push({
          name: "Impuesto",
          valor: (this.totalItems + descuento) * this.form.impuesto.value
        })
      }

// sum all data valor
      let total = 0;
      data.forEach(item => {
        total += item.valor;
      });

      data.push({
        name: "Total",
        valor: total
      });
      return data

      
    },

  },
  created() {
    // get route name
    this.routeName = this.$route.name;
  },
  mounted() {


    CrudService.getCRUD("api/costos").then(
      (data) => {
        this.form.pph_operator = data.pph_operator;
        this.form.history = data.history;
      }
    );
 

  },
  methods: {
    valorByClientType(valor) {
      if (this.clientType.value == 0) {
        return valor
      } else {
        return valor * 0.3 + valor
      }
    },

    costoProd(){

      let costo = 0;

      return costo;

    
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    toArs(value) {
 
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    toUsd(value) {
      
      // format number to currency
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "USD",
      });

    },
    toSelectedValue(value){

      if(this.form.moneda.value == 0){
        return this.toArs(value)
      }else{
        return this.toUsd(value)
      }
      
    },
delItem(itemId){
  //console.log(item)
  // find in this.items
  let index = this.items.findIndex(item => item.id == itemId);
  // remove item
  this.items.splice(index, 1);

  // recalculate total


}, 
      formatUnity(value) {

           switch(value) {
                case 0:
                    return "ml";
                case 1:
                    return "unidad";
                case 2:
                    return "kg";
                case 3:
                    return "mt";
                case 4:
                    return "lt";
                case 5:
                    return "m2";
                case 6:
                    return "mtl";
                case 7:
                    return "gr";
                case 8:
                    return "juego";
                default:
                    return "";
            }
      },
    displayErrors(errors) {
      for (let key in errors) {
        this.validationErrors.push({ field: key, message: errors[key] });

        let error = errors[key];
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error[0],
          life: 3000,
        });
      }
    },
    searchProduct(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredProducts = [...this.products];
        } else {
          this.filteredProducts = this.products.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchClient(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredClients = [...this.clients];
        } else {
          this.filteredClients = this.clients.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchMDO(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMod = [...this.mods];
        } else {
          this.filteredMod = this.mods.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    addItem() {
 
        // check if selected product lenght
        if (!this.selectedProduct.length) {
          this.items.push({
            id: this.selectedProduct.id,
            name:  this.selectedProduct.name,
            qty: this.qtyToAdd,
            valor: this.valorToAdd,
          });
        }else{

          this.items.push({
            id: 0,
            name: this.newItem ? this.newItem : this.selectedProduct,
            qty: this.qtyToAdd,
            valor: this.valorToAdd,
          });

        }

        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.selectedProduct = null;
 
    },
    save() {
      this.submitted = true;
 
      CrudService.createCRUD("api", "costos", this.form).then(
        (data) => {
          this.validationErrors = [];
       //   if (data.status == 400) {
       //     this.displayErrors(data.data);
       //   } else {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Creada correctamente.",
              life: 3000,
          });

          this.$router.push("/costos");

         // }
        }
      );


    },
  },
};
</script>


<style scoped>

  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }

  .card {
    padding-top: 0.25rem;
  }

</style>